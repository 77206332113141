import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, Center, Flex, Heading, Text } from '@chakra-ui/react';
import { useStripe } from '@stripe/react-stripe-js';
import { CheckIcon } from '@/assets/icons';
import AppLink from '@/components/common/AppLink';
import { ErrorBlock } from '@/components/pages/checkout';
import { PaymentService, SubscriptionService } from '@/services';
import { BillingPlanType, Routes, SOMETHING_WENT_WRONG_MESSAGE } from '@/constants';
import { CustomerCard, GAnalyticsClientData, ProductPlan } from '@/types/models';
import { PayAndSubscribeResponse } from '@/types/pages/checkout';
import { LoadingState } from '@/types';

const SuccessesSubscribe = ({
  productPlans,
  plansToUpgrade,
  currentSubscription,
  setSubscriptionState,
  gAnalyticsClientData,
}: {
  productPlans: ProductPlan[];
  plansToUpgrade: BillingPlanType[];
  currentSubscription: PayAndSubscribeResponse | null;
  setSubscriptionState: Dispatch<SetStateAction<LoadingState<PayAndSubscribeResponse | null>>>;
  gAnalyticsClientData: Partial<GAnalyticsClientData>;
}) => {
  const { push } = useRouter();
  const stripe = useStripe();
  const handleStartPracticing = () => push(Routes.Questions);

  const [error, setError] = useState<null | string>(null);
  const [isSubscriptionUpdating, setIsSubscriptionUpdating] = useState(false);

  const [{ data: customerCard }, setCustomerCardState] = useState<LoadingState<CustomerCard | null>>({
    loading: false,
    fetched: false,
    data: null,
  });

  useEffect(() => {
    (async () => {
      try {
        const cardDetails = await PaymentService.getUserCard();

        setCustomerCardState({ loading: false, fetched: true, data: cardDetails });
      } catch (e) {
        setError('123');
      }
    })();
  }, []);

  const handleUpgradeSubscription = async (planType: BillingPlanType) => {
    if (error) {
      setError(null);
    }
    const selectedProduct = productPlans.find((plan) => plan.type === planType);

    if (!selectedProduct || !stripe) {
      return;
    }

    const analyticsData = {
      gAnalyticsClientData,
      userAgent: navigator.userAgent,
    };

    try {
      setIsSubscriptionUpdating(true);

      const {
        id,
        actionRequired,
        clientSecret,
        isOneTimePayment,
        stripeSubscriptionId,
        finalizeProratedAmount,
        paymentIntentId,
        ...restData
      } = await SubscriptionService.upgradeSubscription({
        productId: selectedProduct.stripeProductId,
        subscriptionType: selectedProduct.type,
      });

      if (actionRequired && clientSecret) {
        const { paymentIntent, error: paymentIntentError } = await stripe.confirmCardPayment(clientSecret);

        if (paymentIntentError?.message) {
          // We don't need to revert upgrade subscription for lifetime (isOneTimePayment flag)
          if (isOneTimePayment) {
            return setError(paymentIntentError.message);
          }

          if (!currentSubscription) {
            return setError('Error during subscription update. Please contact the Admin or try later.');
          }

          await SubscriptionService.revertUpgradeSubscription({
            prevSubscriptionId: currentSubscription.id,
            prevSubscriptionType: currentSubscription.subscriptionType as BillingPlanType,
          });

          return setError(paymentIntentError.message);
        }

        if (paymentIntent?.status !== 'succeeded' || !id) {
          return setError(SOMETHING_WENT_WRONG_MESSAGE);
        }

        const confirmedSubscription = await SubscriptionService.checkSubscription({
          id,
          isOneTimePayment,
          isSubscriptionUpgrade: true,
          stripeSubscriptionId,
          finalizeProratedAmount,
          paymentIntentId,
          ...analyticsData,
        });

        setSubscriptionState({ data: confirmedSubscription, loading: false, fetched: true });
      } else {
        setSubscriptionState({
          data: { id, clientSecret, actionRequired, isOneTimePayment, ...restData },
          loading: false,
          fetched: true,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubscriptionUpdating(false);
    }
  };

  return (
    <Center flexDir="column">
      <CheckIcon />
      <Heading fontSize="28px" textAlign="center">
        Congratulations, you are now a premium user!
      </Heading>
      <Button onClick={handleStartPracticing} w="70%" minW="200px" h="60px" colorScheme="red" mt="20px">
        Start Practicing
      </Button>
      {!!plansToUpgrade.length && customerCard && (
        <Center flexDir="column" mt="24px">
          <Heading as="h3" fontSize="24px" textAlign="center">
            Upgrade Subscription (to save money)
          </Heading>
          <Flex fontSize="20px" gap="10px">
            <Text>Your current card is</Text>

            <Flex>
              <Text textTransform="uppercase">{customerCard?.brand}</Text>
              <Text ml="14px">****{customerCard?.lastFour}.</Text>
            </Flex>

            <AppLink href={Routes.Premium} color="blue">
              Update it
            </AppLink>
          </Flex>
          {plansToUpgrade.map((plan) => (
            <Button
              key={plan}
              onClick={() => handleUpgradeSubscription(plan)}
              isLoading={isSubscriptionUpdating}
              colorScheme="green"
              w="70%"
              minW="200px"
              h="60px"
              mt="20px"
            >
              Upgrade to {plan}
            </Button>
          ))}
          <ErrorBlock error={error} mt="20px" />
        </Center>
      )}
    </Center>
  );
};

export default SuccessesSubscribe;
