import React, { FC, useEffect, useState } from 'react';
import { Button, Flex, Link } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useQuestionsStore } from '@/stores';
import { ArrowLeft, ChevronLeft, ChevronRight } from '@/components/common';
import { PrevNextQuestionResponse, Question } from '@/types/models';
import { PreviewQuestionPopover } from '@/components/pages/question/PreviousPageLinkUpdated/components';
import { QuestionService } from '@/services';
import { Routes } from '@/constants';
import { Nullable } from '@/types';
import { ParsedUrlQueryInput } from 'querystring';

// Prev\next question could be only with NULL or ['Premium_Questions'] access groups
export const QuestionNavigation: FC<Partial<Pick<Question, 'id'>>> = ({ id }) => {
  const { push } = useRouter();
  const { filterParams } = useQuestionsStore();
  const [prevAndNextQuestionPreviewData, setPrevAndNextQuestionPreviewData] =
    useState<Nullable<PrevNextQuestionResponse>>(null);

  const handleBack = () => {
    push({ pathname: Routes.Questions, query: filterParams as ParsedUrlQueryInput });
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    (async () => {
      const response = await QuestionService.getPrevAndNextQuestionPreview(id);
      setPrevAndNextQuestionPreviewData(response);
    })();
  }, [id]);

  return (
    <Flex borderRadius="8px" border="1px solid" borderColor="gray.300" color="black.500">
      <Button
        variant="wrapper"
        lineHeight="24px"
        leftIcon={<ArrowLeft />}
        onClick={handleBack}
        borderRightRadius="none"
        color="inherit"
      >
        All questions
      </Button>
      <PreviewQuestionPopover
        questionPreviewProps={prevAndNextQuestionPreviewData?.previousQuestion || null}
        linkType="Previous"
      >
        <Button
          as={Link}
          href={
            prevAndNextQuestionPreviewData?.previousQuestion
              ? `${Routes.Questions}/${prevAndNextQuestionPreviewData?.previousQuestion?.slug}`
              : undefined
          }
          borderRadius="none"
          borderLeft="1px solid"
          borderColor="inherit"
          color="inherit"
          variant="wrapper"
          isDisabled={!prevAndNextQuestionPreviewData?.previousQuestion}
        >
          <ChevronLeft color={prevAndNextQuestionPreviewData ? 'black.500' : 'gray.400'} />
        </Button>
      </PreviewQuestionPopover>

      <PreviewQuestionPopover
        questionPreviewProps={prevAndNextQuestionPreviewData?.nextQuestion || null}
        linkType="Next"
      >
        <Button
          as={Link}
          href={
            prevAndNextQuestionPreviewData?.nextQuestion
              ? `${Routes.Questions}/${prevAndNextQuestionPreviewData?.nextQuestion?.slug}`
              : undefined
          }
          borderRadius="none"
          borderLeft="1px solid"
          borderColor="inherit"
          color="inherit"
          variant="wrapper"
          isDisabled={!prevAndNextQuestionPreviewData?.nextQuestion}
        >
          <ChevronRight color={prevAndNextQuestionPreviewData ? 'black.500' : 'gray.400'} />
        </Button>
      </PreviewQuestionPopover>
    </Flex>
  );
};
