import { Center, CenterProps, ScaleFade, Text } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

interface ErrorBlockProps extends CenterProps {
  error: string | null;
}

export const ErrorBlock = ({ error, ...styleProps }: ErrorBlockProps) => (
  <ScaleFade in={Boolean(error)}>
    <Center backgroundColor="red.100" borderRadius="8px" py="20px" px="32px" my="10px" {...styleProps}>
      <WarningIcon mr="10px" fontSize="24px" />
      <Text>{error}</Text>
    </Center>
  </ScaleFade>
);
